/*=================================*/
/* Header
/*=================================*/
#header {
  @include flexDisplayWithDirection(column);
  font-family: SyncopateRegular;
  @include siteWrapperItemMarginBottom;
  // @include border(green);
}

.siteTitle,
.siteTitle a {
  @include flexDisplayWithDirection(row);
  justify-content: space-between;
  @include fontColorSizeWeight($titleLink, 30px, 500);

  &:hover {
    color: $titleLinkHover;
  }

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($titleLink, 24px, 500);
  }

  // @media only screen and (max-width: $breakSmall) {
  //   @include fontColorSizeWeight($titleLink, 22px, 500);
  // }

  @media only screen and (max-width: $breakExtraSmall) {
    @include fontColorSizeWeight($titleLink, 18px, 500);
  }
}

.mobileNavSeparator {
  display: none;
  border-bottom: $mobileNavSeparator;

  @media only screen and (max-width: $breakMedium) {
    display: block;
  }
}

.siteNavigation {
  clear: both;
  padding: 10px 0 10px 3px;
  background-color: $navBg;

  a,
  a:visited {
    color: $navLink;
    @include fontColorSizeWeight($navLink, 16px, 500);
  }
  a:hover,
  a.active {
    color: $navLinkHoverActive;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    float: left;
    margin: 0 10px 0 0;
  }

  @media only screen and (max-width: $breakMedium) {
    display: none;
  }
}

.navDrawerToggleButton {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: $breakMedium) {
    display: flex;
  }

  @media only screen and (max-width: $breakSmall) {
    padding: 0 0 5px 0;
  }

  @media only screen and (max-width: $breakExtraSmall) {
    padding: 0 0 6px 0;
  }
}

.toggleButtonLine {
  width: 30px;
  height: 2px;
  background: $portRed;
}

.navDrawer {
  display: none;
  height: 100%;
  background: $navBg;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  color: $portBeige;
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;

  button {
    float: right;
  }

  a,
  a:visited {
    color: $navLink;
    @include fontColorSizeWeight($navLink, 18px, 600);
  }
  a:hover,
  a.active {
    color: $navLinkHoverActive;
  }

  ul {
    @include flexDisplayWithDirection(column);
    list-style: none;
    margin: 0;
  }

  li {
    float: left;
    margin: 15px 30px;
  }

  @media only screen and (max-width: $breakMedium) {
    display: block;
  }
}

.navDrawer.open {
  transform: translateX(0);
}
#closeButtonBox {
  width: 50px;
  height: 50px;
  background-color: $portBeige;
  float: right;

  @media only screen and (max-width: $breakExtraSmall) {
    width: 40px;
    height: 40px;
  }
}

.closeButtonLineBottomLeftToTopRight {
  height: 50px;
  width: 2px;
  margin-left: 23px;
  background-color: $portRed;
  transform: rotate(45deg);
  z-index: 1;

  @media only screen and (max-width: $breakExtraSmall) {
    margin-left: 19px;
    height: 40px;
  }
}

.closeButtonLineTopLeftToBottomRight {
  height: 50px;
  width: 2px;
  background-color: $portRed;
  transform: rotate(90deg);
  z-index: 2;

  @media only screen and (max-width: $breakExtraSmall) {
    height: 40px;
  }
}
