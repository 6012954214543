@font-face {
  font-family: SyncopateRegular;
  src: url(../../Syncopate-Regular.ttf);
}

@font-face {
  font-family: SyncopateBold;
  src: url(../../Syncopate-Bold.ttf);
}

@mixin readingFonts {
  font-family: "Helvetica", "Times", "Arial";
}
