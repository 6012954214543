@mixin siteBgColor {
  background: linear-gradient(rgba($portBeige, 0.8), rgba($portRed, 0.5));
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@mixin siteWrapperItemMarginBottom {
  margin-bottom: 20px;

  @media only screen and (max-width: $breakSmall) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: $breakExtraSmall) {
    margin-bottom: 10px;
  }
}

@mixin fontColorSizeWeight($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

@mixin imageHeightWidth($height, $width) {
  height: $height;
  width: $width;
}

@mixin squareImageCircleFrame($pixels) {
  border-radius: ($pixels / 2);
}

@mixin border($color) {
  border: 1px solid $color;
}

@mixin flexDisplayWithDirection($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin contentBasicParagraph {
  @include fontColorSizeWeight($portRed, 16px, 500);
  @include readingFonts;
  line-height: 1.5em;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
