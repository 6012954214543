/*=================================*/
/* Content
/*=================================*/
#content {
  @include siteWrapperItemMarginBottom;
  // @include border(green);
  @include flexDisplayWithDirection(column);
}

.contentTitle {
  font-family: SyncopateRegular;
  @include fontColorSizeWeight($portBlue, 22px, 600);

  margin-bottom: 15px;
  border-bottom: $separator;
  text-align: center;

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($portBlue, 18px, 600);
  }

  @media only screen and (max-width: $breakExtraSmall) {
    @include fontColorSizeWeight($portBlue, 16px, 600);
  }
}

.contentContainer {
  @include flexDisplayWithDirection(column);
  background-color: rgba($portBlue, 0.2);
  box-shadow: $boxShadowColor 0px 0px 5px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  h2 {
    font-family: SyncopateRegular;
    @include fontColorSizeWeight($portRed, 22px, 700);
    text-align: center;
    margin: 10px;
  }

  h3 {
    font-family: SyncopateRegular;
    @include fontColorSizeWeight($portRed, 18px, 700);
    text-align: center;
  }

  & > p {
    @include contentBasicParagraph;
  }

  @media only screen and (max-width: $breakSmall) {
    h3 {
      @include fontColorSizeWeight($portRed, 16px, 700);
    }
    & > p {
      @include fontColorSizeWeight($portRed, 14px, 500);
    }
  }

  @media only screen and (max-width: $breakExtraSmall) {
    h3 {
      @include fontColorSizeWeight($portRed, 14px, 700);
    }
    & > p {
      @include fontColorSizeWeight($portRed, 12px, 500);
    }
  }
}
