* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  @include siteBgColor;
}

#siteWrapper {
  // @include border(red);
  @include flexDisplayWithDirection(column);
  max-width: 1280px;
  margin: 20px auto;
  padding: 0 15px;

  @media only screen and (max-width: $breakMedium) {
    padding: 0 20px;
  }
}

.fadeOut {
  opacity: 0;
  animation-name: fadeOut;
  animation-duration: 2s;
}
.fadeIn {
  opacity: 100;
  animation-name: fadeIn;
  animation-duration: 2s;
}

@keyframes fadeOut {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
