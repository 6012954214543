#searchResultsContainer {
  @include flexDisplayWithDirection(row);

  h2 {
    font-family: SyncopateRegular;
    @include fontColorSizeWeight($portRed, 25px, 600);
    margin: auto;
  }

  h3,
  h4 {
    display: block;
    font-family: SyncopateRegular;
  }

  h3 {
    box-shadow: $boxShadowColor 0px 0px 5px;
    @include fontColorSizeWeight($portRed, 18px, 600);
    background-color: $portBeige;
    margin-top: 5px;
    align-self: center;
    border-radius: 20px;
    padding: 8px;
  }

  h4 {
    @include fontColorSizeWeight($portRed, 16px, 500);
    text-align: center;
  }

  @media only screen and (max-width: $breakSmall) {
    @include flexDisplayWithDirection(column);

    h3 {
      @include fontColorSizeWeight($portRed, 16px, 600);
    }

    h4 {
      @include fontColorSizeWeight($portRed, 14px, 500);
    }
  }
}

#artistDetails {
  // @include border(red);
  @include flexDisplayWithDirection(column);
  justify-content: center;
  align-items: center;
  flex: 0 0 25%;
  white-space: nowrap;
  overflow: hidden;
  border-right: $separator;

  h4 {
    white-space: normal;
  }

  @media only screen and (max-width: $breakSmall) {
    border-right: 0;
    border-bottom: $separator;
    margin-bottom: 20px;
    padding: 10px 0;

    h4 {
      margin-bottom: 10px;
    }
  }
}

#artistDetails img {
  @include imageHeightWidth($artistImageSize, $artistImageSize);
  @include squareImageCircleFrame($artistImageSize);
  box-shadow: $portRed 0px 0px 8px;

  @media only screen and (max-width: $breakSmall) {
    @include imageHeightWidth($artistImageSizeSmall, $artistImageSizeSmall);
    @include squareImageCircleFrame($artistImageSizeSmall);
    margin-bottom: 10px;
  }
}

#topTracks {
  // @include border(red);
  @include flexDisplayWithDirection(column);
  flex: 0 0 75%;
}

.trackContainer {
  @include flexDisplayWithDirection(row);
  flex-wrap: wrap;
  justify-content: center;
}

.track,
.trackImage {
  @include imageHeightWidth($trackImageSize, $trackImageSize);

  @media only screen and (max-width: $breakSmall) {
    @include imageHeightWidth($trackImageSizeSmall, $trackImageSizeSmall);
  }
}

.track {
  position: relative;
  margin: 20px;
  cursor: pointer;
  box-shadow: $portRed 0px 0px 8px;
}

.trackText,
.trackIcon {
  background-color: rgba($trackBgColor, 0.7);
  position: absolute;
  text-align: center;
}

.trackText {
  @include fontColorSizeWeight($trackTextColor, 12px, 600);
  padding: 5px;
  width: 100%;
  bottom: 0px;
  border-radius: 65px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($trackTextColor, 10px, 600);
  }
}

.trackIcon {
  @include imageHeightWidth($trackIconImageSize, $trackIconImageSize);
  @include squareImageCircleFrame($trackIconImageSize);
  @include fontColorSizeWeight($trackTextColor, 20px, 600);
  bottom: 50px;
  left: 50px;
  padding: 3px;

  @media only screen and (max-width: $breakSmall) {
    bottom: 40px;
    left: 30px;
  }
}
