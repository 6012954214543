#gameBoard {
  @include flexDisplayWithDirection(row);
  justify-content: center;

  h2 {
    font-family: SyncopateRegular;
    @include fontColorSizeWeight($portRed, 25px, 600);
    margin: auto;
  }

  h3,
  h4 {
    display: block;
    font-family: SyncopateRegular;
  }

  h3 {
    box-shadow: $boxShadowColor 0px 0px 5px;
    @include fontColorSizeWeight($portRed, 18px, 600);
    background-color: $portBeige;
    margin-top: 5px;
    align-self: center;
    border-radius: 20px;
    padding: 8px;
  }

  h4 {
    @include fontColorSizeWeight($portRed, 16px, 500);
    text-align: center;
  }

  @media only screen and (max-width: $breakSmall) {
    @include flexDisplayWithDirection(column);

    h3 {
      @include fontColorSizeWeight($portRed, 16px, 600);
    }

    h4 {
      @include fontColorSizeWeight($portRed, 14px, 500);
    }
  }
}

#playerMenu {
  // @include border(red);
  @include flexDisplayWithDirection(column);
  justify-content: center;
  align-items: center;
  flex: 0 0 70%;
  white-space: nowrap;
  overflow: hidden;

  h3 {
    border-bottom: $separator;
  }

  h4 {
    white-space: normal;
  }

  @media only screen and (max-width: $breakSmall) {
    border-right: 0;
    border-bottom: $separator;
    margin-bottom: 20px;
    padding: 10px 0;

    h4 {
      margin-bottom: 10px;
    }
  }
}

#gameStats {
  @include flexDisplayWithDirection(column);
  h3,
  h4 {
    margin-bottom: 10px;
  }
}

#gameButtons {
  @include flexDisplayWithDirection(row);
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;

  & > .buttonWrapper {
    margin-bottom: 15px;
  }
}

#drawCardResults {
  // @include border(red);
  @include flexDisplayWithDirection(column);
  justify-content: center;
  align-items: center;
  flex: 0 0 30%;
  border-right: $separator;

  h3 {
    margin-bottom: 10px;
  }

  img {
    @include imageHeightWidth(157px, 113px);
    margin-bottom: 10px;
  }

  .incorrect,
  .correct {
    @include fontColorSizeWeight($portRed, 14px, 500);
    margin-bottom: 10px;
    color: $portBeige;
    align-self: center;
    padding: 8px;
  }

  .correct {
    background-color: $portRed;
  }

  .incorrect {
    background-color: $portBlue;
  }
  @media only screen and (max-width: $breakSmall) {
    border-right: 0;
    border-bottom: $separator;
    padding: 10px;
  }
}
