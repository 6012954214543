#projectsContainer {
  @include flexDisplayWithDirection(row);
  flex-wrap: wrap;
  justify-content: center;
}

.project,
.projectImage {
  @include imageHeightWidth($projectThumbHeight, $projectThumbWidth);

  @media only screen and (max-width: $breakSmall) {
    @include imageHeightWidth(
      $projectThumbHeightSmall,
      $projectThumbWidthSmall
    );
  }

  @media only screen and (max-width: $breakExtraSmall) {
    @include imageHeightWidth(
      $projectThumbHeightExtraSmall,
      $projectThumbWidthExtraSmall
    );
  }
}

.projectImage {
  opacity: 0.6;
  display: block;
  transition: opacity 0.3s linear;
}

.projectImage:hover {
  opacity: 0.8;
  cursor: pointer;
}

.project {
  background-color: $portGray;
  position: relative;
  margin: 20px;
  cursor: pointer;
  box-shadow: $portRed 0px 0px 8px;
}

.projectText {
  font-family: SyncopateRegular;
  background-color: rgba($projectBgColor, 0.9);
  position: absolute;
  text-align: center;
  @include fontColorSizeWeight($projectTextColor, 14px, 600);
  padding: 10px;
  width: 100%;
  bottom: 0px;
  border-radius: 65px;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($projectTextColor, 10px, 600);
  }
  @media only screen and (max-width: $breakExtraSmall) {
    @include fontColorSizeWeight($projectTextColor, 8px, 600);
  }
}

.projectPreviewContainer {
  @include flexDisplayWithDirection(column);
  position: relative;
}

.projectPreviewText {
  @include fontColorSizeWeight($projectTextColor, 14px, 600);
  font-family: SyncopateRegular;
  background-color: rgba($projectBgColor, 0.9);
  position: absolute;
  bottom: 20px;
  box-shadow: $boxShadowColor 0px 0px 5px;
  align-self: center;
  border-radius: 15px;
  padding: 10px;
  max-width: 850px;
  text-align: center;
  cursor: zoom-in;

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($projectTextColor, 12px, 600);
    padding: 8px;
    bottom: 10px;
  }

  @media only screen and (max-width: $breakExtraSmall) {
    padding: 6px;
  }
}

.projectPreviewImage {
  width: 100%;
  max-width: 900px;
  align-self: center;
  cursor: zoom-in;
}

.lightboxContainer {
  background-color: rgba(black, 0.9);
}

.lightboxHeader {
  @include flexDisplayWithDirection(row);
  justify-content: space-between;
  background-color: $navBg;
  color: $portBeige;
  padding: 5px;
}

.leftSide {
  @include flexDisplayWithDirection(column);
  align-items: left;
}

.rightSide {
  @include flexDisplayWithDirection(row);
}

.lightboxHeaderText {
  font-family: SyncopateRegular;
  @include fontColorSizeWeight($portBeige, 18px, 500);
  padding: 5px;
}
