.standardButton {
  font-family: SyncopateRegular;
  @include fontColorSizeWeight($buttonText, 16px, 700);
  background-color: rgba($portBlue, 0.7);
  margin: 0 10px;
  box-shadow: $boxShadowColor 0px 0px 10px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  padding: 11px;
  cursor: pointer;

  &:active {
    box-shadow: $boxShadowColor 0px 0px 10px;
    transform: translateY(1px);
  }

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($buttonText, 14px, 700);
    margin: 0 15px;
    padding: 9px;
  }

  @media only screen and (max-width: $breakExtraSmall) {
    @include fontColorSizeWeight($buttonText, 12px, 700);
    padding: 7px;
  }
}

.loadingSpinner {
  text-align: center;
}

.selectedButton {
  background-color: $portRed;
}

.buttonWrapper {
  @include flexDisplayWithDirection(row);
  justify-content: center;
}

.radioButtonWrapper {
  @include flexDisplayWithDirection(row);
  justify-content: center;

  label {
    cursor: pointer;

    span {
      display: block;
      font-family: SyncopateRegular;
      @include fontColorSizeWeight($buttonText, 16px, 700);
      background-color: rgba($portBlue, 0.7);
      margin: 0 10px;
      box-shadow: $boxShadowColor 0px 0px 10px;
      border-radius: 5px;
      letter-spacing: 1.2px;
      padding: 11px;

      @media only screen and (max-width: $breakSmall) {
        @include fontColorSizeWeight($buttonText, 14px, 700);
        margin: 0 15px;
        padding: 9px;
      }

      @media only screen and (max-width: $breakExtraSmall) {
        @include fontColorSizeWeight($buttonText, 12px, 700);
        padding: 7px;
      }
    }
    input {
      position: absolute;
      visibility: hidden;
    }
    input:checked + span {
      background-color: rgba(93, 52, 72, 1);
    }
  }
}

.searchBarContainer {
  @include flexDisplayWithDirection(row);
  justify-content: center;

  @media only screen and (max-width: $breakSmall) {
    @include flexDisplayWithDirection(column);
  }
}

input[type='search'] {
  @include readingFonts;
  @include fontColorSizeWeight($portBlue, 18px, 500);
  background-color: $basicOffWhite;
  border-radius: 5px;
  letter-spacing: 1.2px;
  padding: 12px;

  @media only screen and (max-width: $breakSmall) {
    @include fontColorSizeWeight($portBlue, 16px, 500);
    margin-bottom: 10px;
    padding: 8px;
  }

  @media only screen and (max-width: $breakExtraSmall) {
  }
}

#backdrop {
  background-color: rgba($portBeige, 1);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  transform: translateY(-100%);
  transition: transform 0.5s ease-out;
}
#backdrop.open {
  transform: translateX(0);
}
