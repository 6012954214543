.newsWrapper {
  @include flexDisplayWithDirection(row);
  @media only screen and (max-width: $breakSmall) {
    @include flexDisplayWithDirection(column);
  }
}

.newsContainer {
  @include flexDisplayWithDirection(column);
  flex: 1 0 50%;
  border: $separator;
  margin-bottom: 10px;

  h2 {
    @include fontColorSizeWeight($portRed, 22px, 600);
  }

  @media only screen and (max-width: $breakMedium) {
    h2 {
      @include fontColorSizeWeight($portRed, 18px, 600);
    }
  }

  @media only screen and (max-width: $breakSmall) {
    h2 {
      @include fontColorSizeWeight($portRed, 14px, 600);
    }
  }
}

.newsArticle {
  @include flexDisplayWithDirection(row);
  padding: 10px;
  // justify-content: center;
  // @include border(red);
  border-bottom: $separator;

  &:nth-of-type(even) {
    background-color: rgba($portBlue, 0.1);
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.articleHeadline {
  @include flexDisplayWithDirection(column);
  flex: 1 0 70%;

  a {
    @include contentBasicParagraph;
    text-decoration: underline;
    padding-right: 2px;
  }

  h4 {
    @include contentBasicParagraph;
    padding-right: 2px;
  }

  h5 {
    @include fontColorSizeWeight($portRed, 12px, 600);
  }

  @media only screen and (max-width: $breakLarge) {
    h4,
    a {
      @include fontColorSizeWeight($portRed, 12px, 500);
    }
  }
}

.articleContent {
  @media only screen and (max-width: $breakLarge) {
    display: none;
  }
}

.articleImage {
  @include flexDisplayWithDirection(column);
  flex: 0 0 30%;

  img {
    max-width: 100%;
    height: auto;
  }
}
