/*=================================*/
/* Footer
/*=================================*/
#footer {
  padding: 10px;
  border-top: $separator;
  // @include border(green);
  @include flexDisplayWithDirection(row);
  justify-content: center;

  img {
    @include imageHeightWidth($footerImageSize, $footerImageSize);
    @include squareImageCircleFrame($footerImageSize);
    margin: 0 10px;
    box-shadow: $portBlue 0px 0px 4px;
  }
}
