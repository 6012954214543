$breakExtraSmall: 355px;
$breakSmall: 475px;
$breakMedium: 768px;
$breakLarge: 1024px;

/*=================================*/
/* 1920 Leyendecker Color Theme    */
/*=================================*/
$portRed: rgb(93, 52, 72);
$portBlue: rgb(31, 68, 124);
$portBeige: rgb(235, 223, 188);
$portGray: rgb(59, 58, 52);
$portOrange: rgb(251, 102, 72);

/*=================================*/
/* Other Colors                    */
/*=================================*/

$basicWhite: #fff;
$basicOffWhite: #fdfdfd;

/*=================================*/
/* Header Colors                   */
/*=================================*/
$titleLink: $portRed;
$titleLinkHover: $portBlue;
$navBg: $portRed;
$navLink: $portBeige;
$navLinkHoverActive: $basicOffWhite;

/*=================================*/
/* Interface Colors                */
/*=================================*/
$buttonText: $portBeige;
$buttonTextHoverActive: $basicOffWhite;

/*=================================*/
/* Content Colors                  */
/*=================================*/
$boxShadowColor: #000;
$trackBgColor: $portBlue;
$trackTextColor: $portBeige;
$projectBgColor: $portBlue;
$projectTextColor: $portBeige;

/*=================================*/
/* Elements                        */
/*=================================*/

$footerImageSize: 46px;

$artistImageSize: 150px;
$artistImageSizeSmall: 125px;

$trackImageSize: 130px;
$trackImageSizeSmall: 90px;
$trackIconImageSize: 30px;

$separator: 1px solid rgba($portBlue, 0.3);
$mobileNavSeparator: 1px solid rgba($portRed, 0.6);
$chatMetaDataSeparator: 1px solid rgba($portRed, 0.3);

$projectThumbHeight: 250px;
$projectThumbWidth: 400px;

$projectThumbHeightSmall: 150px;
$projectThumbWidthSmall: 225px;

$projectThumbHeightExtraSmall: 133px;
$projectThumbWidthExtraSmall: 200px;
